<template>
   <div id="inside">
     
    <div class="container">
      <div class="row">
        <div class="">
        </div>
        <div class="btn-group btn-group-sm">
            <a
              href="/"
              class="btn btn-link d-flex flex-column justify-content-center text-secondary"
              ><i class="fa fa-home fa-fw fa-lg"></i
            ></a>
            <router-link
              to="/dashboard/propostas"
              ><a class="btn btn-link text-secondary">Propostas</a>
            </router-link>
            <router-link
              to="#"
              ><a href="#" class="btn btn-link text-secondary" style="text-decoration: none; cursor: initial;">Acordo Fechado</a>
            </router-link>
        </div>
        
        <div class="col-lg-12">
          <h1> Acordo fechado&nbsp;</h1>
          <p class="text-muted">Pague os boletos abaixo até as datas de vencimento.&nbsp;&nbsp;</p>
        </div>
      </div>
      <div class="container">
      <div class="row">
        <div class="col-lg-2">
          <h6 class="mt-5">Acordo nº 2230</h6>
          <p class="text-info mb-0">CPF 44100110-93<br></p>
          <p class="text-info">Data: 21/04/2021<br></p>
        </div>
        <div class="d-inline-flex flex-wrap flex-column col-acao col-lg-5">
          <div class="card">
            <div class="card-header"><i class="fa fa-fw mr-2 fa-circle text-warning"></i>&nbsp;Boleto ativo</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h6 class="">Acordo 2230</h6>
                  <p>Entrada</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <p class="m-0 text-info">Valor:</p>
                      <h6>R$ 1.000,00</h6>
                    </div>
                    <div class="col-md-6 col-6">
                      <p class="m-0 text-info">Vencimento:</p>
                      <h6>25/05/2021</h6>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <p class="text-info mb-0">Comprovante de pagamento:</p>
                      <p>Pendente</p>
                    </div>
                    <div class="col-md-6 col-6">
                      <a class="btn btn-link mr-1 mt-4 mb-1" href="comprovante.html">Enviar comprovante</a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <p class="text-info mb-0">Status do comprovante:</p>
                      <p class="">Não enviado</p>
                    </div>
                    <div class="col-md-6 col-6">
                      <p class="text-info mb-0">Orientações:</p>
                      <p>-</p>
                    </div>
                  </div>
                  <hr>
                  <p class="text-info mb-1">Linha digitável:</p>
                  <p class="codebar">34196012340120105432102101 020103613131545000</p><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#">Copiar linha digitável</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-download fa-fw"></i> Baixar boleto</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-fw fa-mobile"></i>&nbsp;Receber por SMS</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-fw fa-envelope-o"></i>&nbsp;Receber por e-mail</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="comprovante.html"><i class="fa fa-fw fa-upload"></i>&nbsp;Enviar comprovante</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-inline-flex flex-wrap flex-column col-acao col-lg-5">
          <div class="card">
            <div class="card-header"><i class="fa fa-fw mr-2 fa-circle text-warning"></i>&nbsp;Boleto ativo</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h6 class="">Acordo 2230</h6>
                  <p>Parcela 1/2</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <p class="m-0 text-info">Valor:</p>
                      <h6>R$ 450,00</h6>
                    </div>
                    <div class="col-md-6 col-6">
                      <p class="m-0 text-info">Vencimento:</p>
                      <h6>25/06/2021</h6>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <p class="text-info mb-0">Comprovante de pagamento:</p>
                      <p>Pendente</p>
                    </div>
                    <div class="col-md-6 col-6">
                      <a class="btn btn-link mr-1 mt-4 mb-1" href="comprovante.html">Enviar comprovante</a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <p class="text-info mb-0">Status do comprovante:</p>
                      <p class="">Não enviado</p>
                    </div>
                    <div class="col-md-6 col-6">
                      <p class="text-info mb-0">Orientações:</p>
                      <p>-</p>
                    </div>
                  </div>
                  <hr>
                  <p class="text-info mb-1">Linha digitável:</p>
                  <p class="codebar">34196012340120105432102101 020103613131545000</p><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#">Copiar linha digitável</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-download fa-fw"></i> Baixar boleto</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-fw fa-mobile"></i>&nbsp;Receber por SMS</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-fw fa-envelope-o"></i>&nbsp;Receber por e-mail</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="comprovante.html"><i class="fa fa-fw fa-upload"></i>&nbsp;Enviar comprovante</a>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header"><i class="fa fa-fw mr-2 fa-circle text-warning"></i>&nbsp;Boleto ativo</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h6 class="">Acordo 2230</h6>
                  <p>Parcela 2/2</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <p class="m-0 text-info">Valor:</p>
                      <h6>R$ 450,00</h6>
                    </div>
                    <div class="col-md-6 col-6">
                      <p class="m-0 text-info">Vencimento:</p>
                      <h6>25/07/2021</h6>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <p class="text-info mb-0">Comprovante de pagamento:</p>
                      <p>Pendente</p>
                    </div>
                    <div class="col-md-6 col-6">
                      <a class="btn btn-link mr-1 mt-4 mb-1" href="comprovante.html">Enviar comprovante</a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <p class="text-info mb-0">Status do comprovante:</p>
                      <p class="">Não enviado</p>
                    </div>
                    <div class="col-md-6 col-6">
                      <p class="text-info mb-0">Orientações:</p>
                      <p>-</p>
                    </div>
                  </div>
                  <hr>
                  <p class="text-info mb-1">Linha digitável:</p>
                  <p class="codebar">34196012340120105432102101 020103613131545000</p><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#">Copiar linha digitável</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-download fa-fw"></i> Baixar boleto</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-fw fa-mobile"></i>&nbsp;Receber por SMS</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-fw fa-envelope-o"></i>&nbsp;Receber por e-mail</a><a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="comprovante.html"><i class="fa fa-fw fa-upload"></i>&nbsp;Enviar comprovante</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData } from "@/utils/helpers";

export default {
    name: "HistoricoPagamentos",
     components: {
    },
    data () {
        return {
            loadDeal: false,
            negociacao: null,
            contract :null,
            selectDeal:null,
            digitalLine:null,
            contractFull: null,
            checkSelectAll: false,
            checkContract: false,
            links: [],
        }
    },
    methods: {
        formatValue,
        formatData,

        ...mapActions('minhasDividas', ['ActionDividaNegociar']),

        deactivated() {
            this.divida = null;
            this.negociacao = null;
            this.selectDeal = null;
            this.digitalLine = null;
        },
        voltar() {
             this.$router.push({ 
               name: 'negociarDivida',
               params: { contracts: this.contractFull },
              });
        },
        showDigitalLine(){
          this.digitalLine = this.selectDeal.linhaDigitavel;
        },

        selectAll() {
              var marcar = document.getElementById('all').checked;
              var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
              for (var i = 0; i < lista.length; i++) {
                lista[i].checked = marcar;
              }
        },

        select() {
          var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
          var all = document.getElementById('all');
          if(lista.length <= 2) {
            all.checked = false;
          }
        },

        clear() {
              var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
              for (var i = 0; i < lista.length; i++) {
                lista[i].checked = false;
              }
        },
    },
    async created() {
        this.user.Menus.forEach((menuId) => {
          switch (parseInt(menuId)) {
            case 2:
              this.links.push({
                icon: "",
                text: "Contratos Negociáveis",
                route: "/dashboard/minhas-dividas",
              });
              break;
            case 1:
              this.links.push({
                icon: "",
                text: "Boletos Ativos",
                route: "meusBoletos",
              });
              break;
            case 3:
              this.links.push({
                icon: "",
                text: "Acordos",
                route: "/dashboard/meus-acordos",
              });
              break;
          }
        });
        this.loadDeal = true;
        this.contractFull = this.$route.params.contracts;
        this.contract = this.contractFull[0].environments[0].contracts
        this.loadDeal = false;
    },
    computed: {
      ...mapState("auth", ["user"]),
      isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    }
};
</script>
